import React from 'react';
import { Router } from '@reach/router';
import Layout from '../components/layout/Layout';
import ArticleViewer from '../components/news/ArticleViewer';
import SEO from '../components/layout/Seo';
import NotFoundPage from './404';

const App = () => {
  return (
    <Layout>
      <SEO title="Tango Łódź" />
      <Router>
        <ArticleViewer path="/news/:slug" />
        <NotFoundPage default />
      </Router>
    </Layout>
  );
};

export default App;
